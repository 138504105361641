*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.right-0 {
  right: 0;
}

.z-10 {
  z-index: 10;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mt-auto {
  margin-top: auto;
}

.mt-5 {
  margin-top: 1.25rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.h-5 {
  height: 1.25rem;
}

.max-h-56 {
  max-height: 14rem;
}

.w-full {
  width: 100%;
}

.w-4 {
  width: 1rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-5 {
  width: 1.25rem;
}

.min-w-full {
  min-width: 100%;
}

.shrink-0 {
  flex-shrink: 0;
}

.cursor-default {
  cursor: default;
}

.select-none {
  user-select: none;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-8 {
  gap: 2rem;
}

.gap-3 {
  gap: .75rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(2rem * var(--tw-space-y-reverse) );
}

.overflow-auto {
  overflow: auto;
}

.rounded {
  border-radius: .25rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity) );
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity) );
}

.border-zinc-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 91 / var(--tw-border-opacity) );
}

.border-neutral-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 82 / var(--tw-border-opacity) );
}

.border-stone-600 {
  --tw-border-opacity: 1;
  border-color: rgb(87 83 78 / var(--tw-border-opacity) );
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity) );
}

.border-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgb(234 88 12 / var(--tw-border-opacity) );
}

.border-amber-600 {
  --tw-border-opacity: 1;
  border-color: rgb(217 119 6 / var(--tw-border-opacity) );
}

.border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgb(202 138 4 / var(--tw-border-opacity) );
}

.border-lime-600 {
  --tw-border-opacity: 1;
  border-color: rgb(101 163 13 / var(--tw-border-opacity) );
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity) );
}

.border-emerald-600 {
  --tw-border-opacity: 1;
  border-color: rgb(5 150 105 / var(--tw-border-opacity) );
}

.border-teal-600 {
  --tw-border-opacity: 1;
  border-color: rgb(13 148 136 / var(--tw-border-opacity) );
}

.border-cyan-600 {
  --tw-border-opacity: 1;
  border-color: rgb(8 145 178 / var(--tw-border-opacity) );
}

.border-sky-600 {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity) );
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity) );
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity) );
}

.border-violet-600 {
  --tw-border-opacity: 1;
  border-color: rgb(124 58 237 / var(--tw-border-opacity) );
}

.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity) );
}

.border-fuchsia-600 {
  --tw-border-opacity: 1;
  border-color: rgb(192 38 211 / var(--tw-border-opacity) );
}

.border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-border-opacity) );
}

.border-rose-600 {
  --tw-border-opacity: 1;
  border-color: rgb(225 29 72 / var(--tw-border-opacity) );
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity) );
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity) );
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity) );
}

.bg-zinc-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity) );
}

.bg-neutral-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity) );
}

.bg-stone-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(87 83 78 / var(--tw-bg-opacity) );
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity) );
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity) );
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity) );
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(202 138 4 / var(--tw-bg-opacity) );
}

.bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity) );
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity) );
}

.bg-emerald-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity) );
}

.bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity) );
}

.bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity) );
}

.bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity) );
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity) );
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity) );
}

.bg-violet-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity) );
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity) );
}

.bg-fuchsia-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 38 211 / var(--tw-bg-opacity) );
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity) );
}

.bg-rose-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity) );
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.from-white {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
}

.via-white {
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
}

.to-gray-100 {
  --tw-gradient-to: #f3f4f6;
}

.bg-cover {
  background-size: cover;
}

.p-3 {
  padding: .75rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-1 {
  padding: .25rem;
}

.p-5 {
  padding: 1.25rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity) );
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity) );
}

.text-zinc-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity) );
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity) );
}

.text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity) );
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity) );
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity) );
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity) );
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(202 138 4 / var(--tw-text-opacity) );
}

.text-lime-600 {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity) );
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity) );
}

.text-emerald-600 {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity) );
}

.text-teal-600 {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity) );
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity) );
}

.text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity) );
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity) );
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity) );
}

.text-violet-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity) );
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity) );
}

.text-fuchsia-600 {
  --tw-text-opacity: 1;
  color: rgb(192 38 211 / var(--tw-text-opacity) );
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity) );
}

.text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity) );
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity) );
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity) );
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity) );
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity) );
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.hover\:border-slate-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity) );
}

.hover\:border-gray-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity) );
}

.hover\:border-zinc-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(63 63 70 / var(--tw-border-opacity) );
}

.hover\:border-neutral-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(64 64 64 / var(--tw-border-opacity) );
}

.hover\:border-stone-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(68 64 60 / var(--tw-border-opacity) );
}

.hover\:border-red-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity) );
}

.hover\:border-orange-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(194 65 12 / var(--tw-border-opacity) );
}

.hover\:border-amber-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-border-opacity) );
}

.hover\:border-yellow-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(161 98 7 / var(--tw-border-opacity) );
}

.hover\:border-lime-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(77 124 15 / var(--tw-border-opacity) );
}

.hover\:border-green-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity) );
}

.hover\:border-emerald-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(4 120 87 / var(--tw-border-opacity) );
}

.hover\:border-teal-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(15 118 110 / var(--tw-border-opacity) );
}

.hover\:border-cyan-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(14 116 144 / var(--tw-border-opacity) );
}

.hover\:border-sky-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity) );
}

.hover\:border-blue-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity) );
}

.hover\:border-indigo-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity) );
}

.hover\:border-violet-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(109 40 217 / var(--tw-border-opacity) );
}

.hover\:border-purple-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(126 34 206 / var(--tw-border-opacity) );
}

.hover\:border-fuchsia-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(162 28 175 / var(--tw-border-opacity) );
}

.hover\:border-pink-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(190 24 93 / var(--tw-border-opacity) );
}

.hover\:border-rose-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(190 18 60 / var(--tw-border-opacity) );
}

.hover\:bg-slate-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity) );
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity) );
}

.hover\:bg-zinc-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity) );
}

.hover\:bg-neutral-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity) );
}

.hover\:bg-stone-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(68 64 60 / var(--tw-bg-opacity) );
}

.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity) );
}

.hover\:bg-orange-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(194 65 12 / var(--tw-bg-opacity) );
}

.hover\:bg-amber-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity) );
}

.hover\:bg-yellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(161 98 7 / var(--tw-bg-opacity) );
}

.hover\:bg-lime-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity) );
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity) );
}

.hover\:bg-emerald-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity) );
}

.hover\:bg-teal-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity) );
}

.hover\:bg-cyan-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity) );
}

.hover\:bg-sky-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity) );
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity) );
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity) );
}

.hover\:bg-violet-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity) );
}

.hover\:bg-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity) );
}

.hover\:bg-fuchsia-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(162 28 175 / var(--tw-bg-opacity) );
}

.hover\:bg-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity) );
}

.hover\:bg-rose-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(190 18 60 / var(--tw-bg-opacity) );
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity) );
}

.hover\:text-slate-700:hover {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity) );
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity) );
}

.hover\:text-zinc-700:hover {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity) );
}

.hover\:text-neutral-700:hover {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity) );
}

.hover\:text-stone-700:hover {
  --tw-text-opacity: 1;
  color: rgb(68 64 60 / var(--tw-text-opacity) );
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity) );
}

.hover\:text-orange-700:hover {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity) );
}

.hover\:text-amber-700:hover {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity) );
}

.hover\:text-yellow-700:hover {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity) );
}

.hover\:text-lime-700:hover {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity) );
}

.hover\:text-green-700:hover {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity) );
}

.hover\:text-emerald-700:hover {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity) );
}

.hover\:text-teal-700:hover {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity) );
}

.hover\:text-cyan-700:hover {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity) );
}

.hover\:text-sky-700:hover {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity) );
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity) );
}

.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity) );
}

.hover\:text-violet-700:hover {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity) );
}

.hover\:text-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity) );
}

.hover\:text-fuchsia-700:hover {
  --tw-text-opacity: 1;
  color: rgb(162 28 175 / var(--tw-text-opacity) );
}

.hover\:text-pink-700:hover {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity) );
}

.hover\:text-rose-700:hover {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity) );
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.focus\:border-slate-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity) );
}

.focus\:border-gray-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity) );
}

.focus\:border-zinc-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(63 63 70 / var(--tw-border-opacity) );
}

.focus\:border-neutral-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(64 64 64 / var(--tw-border-opacity) );
}

.focus\:border-stone-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(68 64 60 / var(--tw-border-opacity) );
}

.focus\:border-red-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity) );
}

.focus\:border-orange-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(194 65 12 / var(--tw-border-opacity) );
}

.focus\:border-amber-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-border-opacity) );
}

.focus\:border-yellow-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(161 98 7 / var(--tw-border-opacity) );
}

.focus\:border-lime-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(77 124 15 / var(--tw-border-opacity) );
}

.focus\:border-green-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity) );
}

.focus\:border-emerald-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(4 120 87 / var(--tw-border-opacity) );
}

.focus\:border-teal-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(15 118 110 / var(--tw-border-opacity) );
}

.focus\:border-cyan-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(14 116 144 / var(--tw-border-opacity) );
}

.focus\:border-sky-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity) );
}

.focus\:border-blue-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity) );
}

.focus\:border-indigo-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity) );
}

.focus\:border-violet-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(109 40 217 / var(--tw-border-opacity) );
}

.focus\:border-purple-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(126 34 206 / var(--tw-border-opacity) );
}

.focus\:border-fuchsia-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(162 28 175 / var(--tw-border-opacity) );
}

.focus\:border-pink-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(190 24 93 / var(--tw-border-opacity) );
}

.focus\:border-rose-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(190 18 60 / var(--tw-border-opacity) );
}

.focus\:text-slate-700:focus {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity) );
}

.focus\:text-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity) );
}

.focus\:text-zinc-700:focus {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity) );
}

.focus\:text-neutral-700:focus {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity) );
}

.focus\:text-stone-700:focus {
  --tw-text-opacity: 1;
  color: rgb(68 64 60 / var(--tw-text-opacity) );
}

.focus\:text-red-700:focus {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity) );
}

.focus\:text-orange-700:focus {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity) );
}

.focus\:text-amber-700:focus {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity) );
}

.focus\:text-yellow-700:focus {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity) );
}

.focus\:text-lime-700:focus {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity) );
}

.focus\:text-green-700:focus {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity) );
}

.focus\:text-emerald-700:focus {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity) );
}

.focus\:text-teal-700:focus {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity) );
}

.focus\:text-cyan-700:focus {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity) );
}

.focus\:text-sky-700:focus {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity) );
}

.focus\:text-blue-700:focus {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity) );
}

.focus\:text-indigo-700:focus {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity) );
}

.focus\:text-violet-700:focus {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity) );
}

.focus\:text-purple-700:focus {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity) );
}

.focus\:text-fuchsia-700:focus {
  --tw-text-opacity: 1;
  color: rgb(162 28 175 / var(--tw-text-opacity) );
}

.focus\:text-pink-700:focus {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity) );
}

.focus\:text-rose-700:focus {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity) );
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-slate-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(71 85 105 / var(--tw-ring-opacity) );
}

.focus\:ring-gray-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity) );
}

.focus\:ring-zinc-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(82 82 91 / var(--tw-ring-opacity) );
}

.focus\:ring-neutral-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(82 82 82 / var(--tw-ring-opacity) );
}

.focus\:ring-stone-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(87 83 78 / var(--tw-ring-opacity) );
}

.focus\:ring-red-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(220 38 38 / var(--tw-ring-opacity) );
}

.focus\:ring-orange-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 88 12 / var(--tw-ring-opacity) );
}

.focus\:ring-amber-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(217 119 6 / var(--tw-ring-opacity) );
}

.focus\:ring-yellow-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(202 138 4 / var(--tw-ring-opacity) );
}

.focus\:ring-lime-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(101 163 13 / var(--tw-ring-opacity) );
}

.focus\:ring-green-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(22 163 74 / var(--tw-ring-opacity) );
}

.focus\:ring-emerald-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(5 150 105 / var(--tw-ring-opacity) );
}

.focus\:ring-teal-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(13 148 136 / var(--tw-ring-opacity) );
}

.focus\:ring-cyan-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity) );
}

.focus\:ring-sky-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(2 132 199 / var(--tw-ring-opacity) );
}

.focus\:ring-blue-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity) );
}

.focus\:ring-indigo-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity) );
}

.focus\:ring-violet-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(124 58 237 / var(--tw-ring-opacity) );
}

.focus\:ring-purple-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity) );
}

.focus\:ring-fuchsia-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(192 38 211 / var(--tw-ring-opacity) );
}

.focus\:ring-pink-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(219 39 119 / var(--tw-ring-opacity) );
}

.focus\:ring-rose-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(225 29 72 / var(--tw-ring-opacity) );
}

@media (min-width: 1024px) {
  .lg\:min-h-screen {
    min-height: 100vh;
  }

  .lg\:w-4\/12 {
    width: 33.3333%;
  }

  .lg\:w-8\/12 {
    width: 66.6667%;
  }

  .lg\:max-w-3xl {
    max-width: 48rem;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:p-8 {
    padding: 2rem;
  }
}

@media (min-width: 1280px) {
  .xl\:p-12 {
    padding: 3rem;
  }

  .xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

/*# sourceMappingURL=index.057005e9.css.map */
